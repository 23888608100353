.border-theme {
  border-color: #ae0102 !important;
}

.detailSwiper {
  height: 100vh
}

.detailSwiper .swiper {
  width: 100%;
  height: 100%;
}

.detailSwiper .swiper-wrapper {
  height: 100%;
}

.detailSwiper .swiper-button-next {
  color: white;
}

.detailSwiper .swiper-button-prev {
  color: white;

}

.detailSwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-color{
  color: var(--theme-color)
}