.swiper {
    width: 100%;
    height: 100vh;
}
.BrandSlider {
    width: 100%;
    height: 40vh;
}
.listingSlider {
    width: 100%;
    height: 100vh;
}
.ReviewSlider {
    width: 100%;
    height: 100vh;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 35vh;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}
.listingSlider .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}
.ReviewSlider .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 130px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}